import gql from 'graphql-tag';

export default gql`
mutation insertTag($name: String!, $groupId: Int!) {
  insert_tags(objects: {name: $name, group_id: $groupId}) {
    returning {
      tag_id
    }
  }
}
`;
