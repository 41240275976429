import GetAllItemsOfTeamInGroup from '@/graphQlQueries/queries/getAllItemsOfTeamInGroup';

/*
!!! IMPORTANT: if you use this mixing you have to set the itemPath in another component!!!
 */
const checkAccessForTeamUser = {
  data: () => ({
    itemPath: null,
  }),
  apollo: {
    allItemsIdsOfTeam: {
      query: GetAllItemsOfTeamInGroup,
      variables() {
        return {
          teamIdArray: this.teamIdsOfUser,
          groupId: this.groupId,
        };
      },
      update(data) {
        if (
          this.$route.fullPath !== '/'
          && !this.checkForExistingTeamItemsInCurrentItemPath(data.teams_items)
          && !this.userObject.isSysAdmin
        ) {
          this.$router.push('/404').catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        }
        return data.teams_items;
      },
      skip() {
        return this.isGroupUser || !this.groupId || !this.teamIdsOfUser.length || !this.itemPath;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    checkForExistingTeamItemsInCurrentItemPath(teamsItems) {
      let teamItemIsInCurrentItemPath = false;
      teamsItems.some((teamsItem) => {
        if (teamsItem.folder !== null) {
          const folderIdForPath = `f${teamsItem.folder.folder_id}`;
          if (this.itemPath.search(folderIdForPath) !== -1) {
            teamItemIsInCurrentItemPath = true;
            return true;
          }
        }
        if (teamsItem.project !== null) {
          const projectIdForPath = `pr${teamsItem.project.project_id}`;
          if (this.itemPath.search(projectIdForPath) !== -1) {
            teamItemIsInCurrentItemPath = true;
            return true;
          }
        }
        return false;
      });
      return teamItemIsInCurrentItemPath;
    },
  },
};

export default checkAccessForTeamUser;
