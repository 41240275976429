import gql from 'graphql-tag';

export default gql`
query getAllTagsOfGroup($groupId: Int!) {
  tags(where: {group_id: {_eq: $groupId}}) {
    tag_id
    name
  }
}
`;
