import gql from 'graphql-tag';

export default gql`
query GetLanguageById($languageId: Int!) {
  languages_by_pk(language_id: $languageId) { 
    name
    handle
  }
}
`;
