import gql from 'graphql-tag';

export default gql`
query getAllPageAndProjectIdsByTagId($tagId: Int!) {
  tags_by_pk(tag_id: $tagId) {
    name
    pages_tags {
      page_id
    }
    projects_tags {
      project_id
    }
  }
}
`;
